/* styles.css */
.transition-enter {
    max-height: 0;
    overflow: hidden;
}
.transition-enter-active {
    max-height: 20em;  /* You may need to adjust this value to fit your content */
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
}
.transition-exit {
    max-height: 20em;  /* Ensure this value matches the value in .transition-enter-active */
    overflow: hidden;
}
.transition-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
}
